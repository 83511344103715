/*鱼竿事件*/
import {ref} from "vue";

let line_rotate = ref(20)
let touch_set = ref(null) //蓄力框移动的定时器
let tmp = ref(0)  //蓄力框移动的距离
let touch_end = ref(false) //甩杆动画 .5s
let touch_begin = ref(false) //蓄力动画 1s
let touch_begin_flag = ref(true) // 蓄力动画是否结束
let touch_end_flag = ref(true) //抛竿动画是否结束
let touch_end_time = ref(null) //如果蓄力没结束就抛竿的定时器判断
let float_flag = ref(false) //是否显示浮漂
let float_flag_width = ref(32/375*window.document.body.clientWidth) //上鱼提醒标志的宽度
let float_height = ref(51) //鱼漂的高度，减少高度，实现下沉的效果
let float_left = ref(60) //鱼漂的左偏移
let float_top = ref(40) //鱼漂的顶部偏移
let chuidiao_time = ref(null) //垂钓定时器，垂钓随机时间，控制是否咬钩
//浮漂偏移的最大距离和最小距离 防止超过背景水域
const float_top_min = 34;
const float_top_max = 70;
let touch_abler = ref(false) //用于检测是否通过触摸，防止在多次放开

export {line_rotate,touch_set,tmp,touch_end,touch_begin,touch_begin_flag,touch_end_flag,touch_end_time,float_flag,float_flag_width,float_height,float_left,float_top,chuidiao_time,float_top_max,float_top_min,touch_abler}
