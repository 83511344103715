/*控制鱼咬钩 顶部的条条*/
import {
    fishing_all_type,
    fishing_type,
    gameDjs,
    mark,
    shebei,
    sleep,
    time as gametime,
    fishing_error as fishing_error_info,
    fishing_all_error
} from "@/util/util";
import {ref, watch} from "vue";
import {float_height, float_top, line_rotate} from "@/util/rod";
import {range_top, range_top_max, rushuiStatus} from "@/util/strength";
/*常量*/
const client_width = window.document.body.clientWidth //屏幕宽度
const fill_width = 13.409 //35/261*100 //填充物的长度
const fill_left_max = 82.375 //(240-35+10)/261*100 //填充物最大偏移值
const fill_left_min = 3.831 //10/261*100 //填充物最小偏移值
const fill_left_center = 43.295 //(261-35)/2/261*100
const target_left_center = 34.29 //(261-82)/2/261*100
const mubiao_w = 31.41 //82/261*100 //移动目标框的长度
let gamma = ref(0) //陀螺仪倾斜角度 负为左 正为右
let fill_left = ref(fill_left_center) //填充物页面上左偏移值
let target_left = ref(target_left_center) //目标框页面上左偏移值
const target_su = 0.2 //目标框移动速度
const ios_fill_su = 0.7 //ios填充物移动速度
const android_fill_su = 0.4 //安卓

let time = ref(null) //填充物进入目标框的初始时间
let jianchi = ref(0) //填充物进入目标框所持续的时间
let notime = ref(null)
let nojianchi = ref(0)
let now_fishing_time = ref(0) //本次上鱼所需要花的时间 根据鱼的斤数来判断 斤数越大 时间越久

let fishing_weight = ref(0) //鱼的重量
let fishing_count = ref(0) //鱼的条数

/*标志*/
let flag = ref(false) //目标框是否处于移动中
let isFishing = ref(false) //是否上鱼
let isFlagBig = ref(false) //上鱼标志是否播放放大缩小动画
let isPopFlag = ref(false) //是否展示成功上鱼弹窗
let isErrorPopFlag = ref(false) //是否展示上鱼失败弹窗
let succStatus = ref(1)

/*定时器*/
let gamma_set = ref(null) //安卓手机的定时器 让填充物定时移动
let djs = ref(null) //目标框的移动定时器
let float_interval = ref(null) //鱼漂下潜动画

watch(isFishing,(n)=>{
    if(n){
        //鱼漂下潜动画
        float_interval.value = setInterval(()=>{
            float_height.value>=0?float_height.value--:clearInterval(float_interval.value)
        },10)
        float_top.value+=2
        sleep(1000).then(()=>{
            isFlagBig.value = true
        })
        //增加手机陀螺仪监听
        window.addEventListener("deviceorientation", orientationHandler, false);
        //随机脱钩
        if(Math.random()<0.1){
            //定时器 脱钩 结束游戏
            sleep(Math.random()*1000+1000).then(()=>{
                fishing_error()
                return
            })
        }
    }
})

const orientationHandler = (e) => {
    gamma.value = Math.round(e.gamma) //获得当前手机倾斜度
    if(!shebei.value){ //不是安卓设备的填充物移动方法
        iosFillLeft()
        jiancejinkuang()
    }else{
        //安卓设备 使用定时 自动增长
        androidFillLeft()
    }

}

const iosFillLeft = () => {
    if(gamma.value>=0){
        if(fill_left.value>=fill_left_max){
            return
        }
        fill_left.value+=ios_fill_su
        if(line_rotate.value >= 40){
            return
        }
        line_rotate.value+=0.5
    }else{
        if(fill_left.value<=fill_left_min){
            return
        }
        fill_left.value-=ios_fill_su
        if(line_rotate.value <= 0){
            return
        }
        line_rotate.value-=0.5
    }
}

const androidFillLeft = () => {
    if(gamma.value>=0){
        clearInterval(gamma_set.value)
        gamma_set.value = setInterval(()=>{
            jiancejinkuang()
            if(fill_left.value>=fill_left_max || !fill_left.value){
                return
            }
            fill_left.value+=android_fill_su
            if(line_rotate.value >= 40){
                return
            }
            line_rotate.value+=0.5
        },10)
    }else{
        clearInterval(gamma_set.value)
        gamma_set.value = setInterval(()=>{
            jiancejinkuang()
            if(fill_left.value<=fill_left_min || !fill_left.value){
                return
            }
            fill_left.value-=android_fill_su
            if(line_rotate.value <= 0){
                return
            }
            line_rotate.value-=0.5
        },10)
    }
}

const jiancejinkuang = () => {
    //是否处于目标框中
    /*fill_left.value >= target_left.value-fill_width && fill_left.value+fill_width <= target_left.value+mubiao_w+fill_width*/
    if(fill_left.value >= target_left.value-fill_width && fill_left.value <= target_left.value+mubiao_w && isFishing.value && gametime.value>0){
        //
        notime.value = null

        if(time.value){
            //已经持续处于框中
            console.log('持续进框')
            jianchi.value = new Date().getTime()-time.value //持续更新持久时间
            /*small_fill_width.value = jianchi.value/now_fishing_time.value*100*/
            if(jianchi.value>=now_fishing_time.value && isFishing.value){
                //持续时间超过 并且 处于上鱼状态
                fishing_success()

                return;
            }
        }else{
            //初步进框
            console.log('初步进框')
            document.querySelector('.smalltiao .content').setAttribute('style','width:100%;transition:all '+now_fishing_time.value+'ms linear')
            time.value = new Date().getTime()
        }

        //改变目标框的位置
        if(!flag.value){
            flag.value=true
            let num = Math.random()*((261-82-10)/261*100)+10/261*100 //目的地
            if(num>target_left.value){
                djs.value = setInterval(()=>{
                    if(num>target_left.value){
                        target_left.value+=target_su
                    }else{
                        flag.value = false
                        clearInterval(djs.value)
                    }
                },10)
            }else{
                djs.value = setInterval(()=>{
                    if(num<target_left.value){
                        target_left.value-=target_su
                    }else{
                        flag.value = false
                        clearInterval(djs.value)
                    }
                },10)
            }
        }
    }else if(isFishing.value){
        time.value = null;

        if(notime.value){
            //已经持续处于离框
            console.log('持续离框',notime.value)
            nojianchi.value = new Date().getTime()-notime.value //持续更新持久时间
            if(nojianchi.value >= 3000 && isFishing.value){
                //鱼跑了
                fishing_error()
            }
        }else{
            //初步离框
            /*small_fill_width.value = 0*/
            console.log('初步离框')
            document.querySelector('.smalltiao .content').removeAttribute('style')
            notime.value = new Date().getTime()
        }

    }
}

const fishing_success = () => {
    //关闭倒计时
    clearInterval(gameDjs.value)
    gameDjs.value = null
    rushuiStatus.value = 0
    //恢复初始化
    recovery()
    if(Math.random()<0.05){
        fishing_error_info.value = fishing_all_error[Math.round(Math.random()+1)]
        isErrorPopFlag.value = true
    }else{
        succStatus.value++
        isPopFlag.value = true
        mark.value=(mark.value+fishing_weight.value).toFixed(2)-0 //增加总斤数
        fishing_count.value++ //增加总条数
    }
}

const fishing_error = () => {
    //鱼跑了
    isErrorPopFlag.value = true
    fishing_error_info.value = fishing_all_error[0]
    rushuiStatus.value = 0
    //关闭倒计时
    clearInterval(gameDjs.value)
    gameDjs.value = null

    recovery()
}

const recovery = () => {
    clearInterval(djs.value)//关闭目标框的移动
    clearInterval(gamma_set.value)//关闭安卓的定时增长
    flag.value=false //移动框未开始移动
    window.removeEventListener("deviceorientation", orientationHandler, false) //卸载陀螺仪监听
    isFishing.value=false //取消中鱼
    time.value = null //删除进框时间
    jianchi.value = 0 //进框时间长度清空
    notime.value = null
    nojianchi.value = 0
    document.querySelector('.smalltiao .content').removeAttribute('style')
    /*恢复原位*/
    range_top.value = range_top_max
    line_rotate.value = 20
    /*将力度条归零*/
    target_left.value = null
    document.querySelector('.mubiao').setAttribute('style','transition: all 1s;left:'+target_left_center+'%')
    fill_left.value = null
    document.querySelector('.tianchong').setAttribute('style','transition: all 1s;left:'+fill_left_center+'%')
    sleep(1000).then(()=>{
        target_left.value = target_left_center
        fill_left.value = fill_left_center
        document.querySelector('.mubiao').removeAttribute('style')
        document.querySelector('.tianchong').removeAttribute('style')
    })
}

/*获取本次钓上来的鱼的名字，图片，斤数，所需要花费的时间*/
const getFishingInfo = () => {
    let bfb = range_top.value/range_top_max*100
    let rdm = Math.random()*100
    let index = 0
    //处理鱼的概率
    if(bfb<30){
        //高 0-2 20%  3-5 10%  6-7 5%
        if(rdm<20){
            index = 0
        }else if(rdm<40){
            index = 1
        }else if(rdm<60){
            index = 2
        }else if(rdm<70){
            index = 3
        }else if(rdm<80){
            index = 4
        }else if(rdm<90){
            index = 5
        }else if(rdm<95){
            index = 6
        }else if(rdm<100){
            index = 7
        }
    }else if(bfb<60){
        //0-2 10%  3-5 20%  6-7 5%
        //中
        if(rdm<10){
            index = 0
        }else if(rdm<20){
            index = 1
        }else if(rdm<30){
            index = 2
        }else if(rdm<50){
            index = 3
        }else if(rdm<70){
            index = 4
        }else if(rdm<90){
            index = 5
        }else if(rdm<95){
            index = 6
        }else if(rdm<100){
            index = 7
        }
    }else{
        //低
        if(rdm<5){
            index = 0
        }else if(rdm<10){
            index = 1
        }else if(rdm<20){
            index = 2
        }else if(rdm<30){
            index = 3
        }else if(rdm<40){
            index = 4
        }else if(rdm<60){
            index = 5
        }else if(rdm<80){
            index = 6
        }else if(rdm<100){
            index = 7
        }
    }
    fishing_type.value = fishing_all_type[index]
    // 斤数的处理
    //10 245 245/10=24.5  245/245=1  245/120=2
    //1 2 3 4 5 6 7 8 9 ... 20 24.5  *  ?
    // fishing_weight.value = Math.round((range _top_max/range_top.value)*(Math.random()*100))
    /*if(fishing_weight.value>100){
        fishing_weight.value = Math.round(Math.random()*50+50)
    }*/
    fishing_weight.value = (Math.random()*(fishing_type.value.max-fishing_type.value.min)+fishing_type.value.min).toFixed(2)-0
    // 时间的处理
    now_fishing_time.value = fishing_weight.value-50<0?2000:(1+(fishing_weight.value-50)/80)*3000
}


export {orientationHandler,succStatus,getFishingInfo,isErrorPopFlag,notime,float_interval,nojianchi,fishing_count,client_width,fishing_weight,isPopFlag,fill_width,fill_left_max,fill_left_min,mubiao_w,gamma,gamma_set,fill_left,target_left,time,jianchi,flag,djs,isFishing,isFlagBig,fill_left_center,target_left_center,now_fishing_time}
