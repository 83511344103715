<template>
  <audio
      id="bgmusic"
      ref="setBgMusic"
      :src="require('./assets/bg.mp3')"
      loop
      autoplay
      style="display: none"
  ></audio>
<!--  <audio
      id="youdongmusic"
      ref="setyoudongMusic"
      :src="require('./assets/youdong.aac')"
      style="display: none"
  ></audio>-->
  <audio
      id="succmusic"
      ref="setsuccMusic"
      :src="require('./assets/succ.aac')"
      style="display: none"
  ></audio>
  <audio
      id="rushuimusic"
      ref="setrushuiMusic"
      :src="require('./assets/rushui.mp3')"
      style="display: none"
      loop
  ></audio>
  <audio
      id="shuaiganmusic"
      ref="setshuaiganMusic"
      :src="require('./assets/shuaigan.wav')"
      style="display: none"
  ></audio>
<!--  <audio
      id="tiaoyuemusic"
      ref="settiaoyueMusic"
      :src="require('./assets/tiaoyue.aac')"
      style="display: none"
  ></audio>-->
  <router-view v-model:bgStatus="bgStatus" />
<!-- v-model:youdongStatus="youdongStatus"
               v-model:succStatus="succStatus" v-model:rushuiStatus="rushuiStatus"
               v-model:tiaoyueStatus="tiaoyueStatus" -->
</template>

<script setup>
import {ref, watch} from "vue";
import {rushuiStatus, shuaiganStatus} from "@/util/strength";
import {succStatus} from "@/util/control";

let bgStatus = ref(1) //关闭音乐的标志 默认打开 >0打开 <0关闭
let setBgMusic = ref(null)
watch(bgStatus,()=>{
  if(bgStatus.value>0){
    console.log('播放音乐')
    setBgMusic.value.play();
  }else{
    //关闭音乐
    setBgMusic.value.pause();
  }
})

/*let youdongStatus = ref(1)
let setyoudongMusic = ref(null)
watch(youdongStatus,()=>{
  if(youdongStatus.value>0){
    setyoudongMusic.value.play();
  }else{
    //关闭音乐
    setyoudongMusic.value.pause();
  }
})*/

let setsuccMusic = ref(null)
watch(succStatus,()=>{
  if(succStatus.value>0){
    setsuccMusic.value.play();
  }else{
    //关闭音乐
    setsuccMusic.value.pause();
  }
})


let setrushuiMusic = ref(null)
watch(rushuiStatus,()=>{
  if(rushuiStatus.value>0){
    setrushuiMusic.value.play();
  }else{
    //关闭音乐
    setrushuiMusic.value.pause();
  }
})

let setshuaiganMusic = ref(null)
watch(shuaiganStatus,()=>{
  if(shuaiganStatus.value>0){
    setshuaiganMusic.value.play();
  }else{
    //关闭音乐
    setshuaiganMusic.value.pause();
  }
})

/*
let tiaoyueStatus = ref(1)
let settiaoyueMusic = ref(null)
watch(tiaoyueStatus,()=>{
  if(tiaoyueStatus.value>0){
    settiaoyueMusic.value.play();
  }else{
    //关闭音乐
    settiaoyueMusic.value.pause();
  }
})
*/

</script>

<style lang="scss">
html,body,#app{
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
</style>
