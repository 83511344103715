import {ref} from "vue";
import {
    djs, fill_left, fill_left_center,
    fishing_count,
    flag, float_interval,
    gamma_set, isErrorPopFlag, isFishing,
    isPopFlag, jianchi, nojianchi, notime,
    orientationHandler, small_fill_width,
    target_left,
    target_left_center,
    time as control_time
} from "@/util/control";
import {
    chuidiao_time,
    float_flag,
    line_rotate,
    touch_begin_flag,
    touch_end_flag,
    touch_end_time,
    touch_set
} from "@/util/rod";
import {range_top, range_top_max} from "@/util/strength";
const GameTime = 100 //游戏时长 /s
let GameBeginDjs = ref(3)

/*游戏倒计时定时器*/
let gameDjs = ref(null)

//鱼的所有种类
const fishing_all_type = [
    {img:require('../assets/fishing_3.png'),name:'狗鱼',min:40,max:100},
    {img:require('../assets/fishing_4.png'),name:'海贝思',min:50,max:80},
    {img:require('../assets/fishing_5.png'),name:'红鳟鱼',min:20,max:30},
    {img:require('../assets/fishing_6.png'),name:'旗鱼',min:30,max:110},
    {img:require('../assets/fishing_1.png'),name:'银鲤鱼',min:10,max:20},
    {img:require('../assets/fishing_2.png'),name:'鲱鱼',min:20,max:30},
    {img:require('../assets/fishing_7.png'),name:'梭鲈鱼',min:10,max:20},
    {img:require('../assets/fishing_8.png'),name:'金枪鱼',min:40,max:70},
]
//页面展示中鱼信息
let fishing_type = ref(null)

const fishing_all_error = [
    {img:require('../assets/laji1.png'),name:'鱼断线了',msg:'斤数：？斤'},
    {img:require('../assets/laji2.png'),name:'废弃鞋',msg:'？？？？？'},
    {img:require('../assets/laji3.png'),name:'垃圾袋',msg:'？？？？？'}
]
let fishing_error = ref(null)
fishing_error.value = fishing_all_error[0]

const sleep = (time) => {
    return new Promise((resolve, reject) => {
        setTimeout(resolve,time)
    })
}

//阻止ios长按事件
const iostouch = () => {
    return
}

//获取当前设备是否为安卓
let shebei = ref(null)
shebei.value = Boolean(navigator.userAgent.match(/android/ig))

let time = ref(GameTime)
let mark = ref(0)

const startTime = () => {
    if(gameDjs.value){
        clearInterval(gameDjs.value) //关闭倒计时
        gameDjs.value = null
    }
    gameDjs.value = setInterval(()=>{
        if(time.value>0){
            time.value--
        }else{
            //游戏结束
            //发送分数
            if(mark.value>0){
                //发送分数
                fetch('https://douyin.91ziyan.com/member/api/getScore', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: handleGetParams({score:mark.value,token:localStorage.getItem('uid'),gid:2})
                }).then(res=>{
                    return res.json()
                }).then(data=>{
                    //state == 1 成功
                    if(data.state != 1){
                        alert(data.msg)
                        localStorage.removeItem('uid')
                    }
                })
            }
            window.removeEventListener("deviceorientation", orientationHandler, false) //卸载陀螺仪监听
            flag.value=false //移动框未开始移动标志
            isFishing.value = false
            /*控制按钮无法点击*/
            touch_begin_flag.value = false
            touch_end_flag.value = false
            clearInterval_all()
        }
    },1000)
}

//关闭上鱼弹窗
const click_animation = () =>{
    isPopFlag.value = false
    isErrorPopFlag.value = false
    startTime()
}

//再来一次
const again = () => {
    init()
}

const GameBeginEvent = () => {
    return new Promise((resolve, reject) => {
        let djs = setInterval(()=>{
            --GameBeginDjs.value
            if(GameBeginDjs.value <= 0 ){
                clearInterval(djs)
                resolve()
            }
        },1000)
    })
}

//游戏初始化
const init = () => {
    //进度条，蓄力条恢复原位
    target_left.value = target_left_center
    fill_left.value = fill_left_center
    range_top.value = range_top_max

    //关闭上钩标志
    float_flag.value = false
    isFishing.value = false

    //关闭右下角按钮
    touch_begin_flag.value = false
    touch_end_flag.value = false

    //分数清空
    mark.value = 0
    //条数清空
    fishing_count.value = 0
    //鱼竿度数
    line_rotate.value = 20

    //进框时间以及出框时间清空
    control_time.value = null
    jianchi.value = 0
    notime.value = null
    nojianchi.value = 0
    document.querySelector('.smalltiao .content').removeAttribute('style')

    //关闭上鱼弹窗
    isPopFlag.value = false

    //倒计时初始化
    time.value = GameTime
    GameBeginDjs.value = 3
    GameBeginEvent().then(()=>{
        sleep(500).then(()=>{
            --GameBeginDjs.value
        })
        //打开右下角按钮
        touch_begin_flag.value = true
        touch_end_flag.value = true
        //打开倒计时
        startTime()
    })
}

//游戏清理
const clearInterval_all = () => {
    //关闭监听
    window.removeEventListener("deviceorientation", orientationHandler, false) //卸载陀螺仪监听
    clearInterval(gamma_set.value)
    clearInterval(djs.value)
    clearInterval(gameDjs.value)
    clearInterval(chuidiao_time.value)
    clearInterval(float_interval.value)
    clearInterval(touch_end_time.value)
    clearInterval(touch_set.value)
}

const handleGetParams=(data)=>{
    if(data){
        let paramsArr = [];
        Object.keys(data).forEach(key=>{
            paramsArr.push(key+"="+data[key]);
        })
        return paramsArr.join('&');
    }
    return ''
}

export {sleep,fishing_error,fishing_all_error,iostouch,GameBeginDjs,shebei,time,mark,startTime,fishing_type,fishing_all_type,click_animation,gameDjs,again,GameTime,init,clearInterval_all,handleGetParams}
