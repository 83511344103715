/*左边抛竿蓄力事件*/
import {
    djs,
    fill_left,
    flag,
    gamma_set, isFishing, isFlagBig, jianchi,
    orientationHandler,
    target_left, time, target_left_center, fill_left_center, getFishingInfo, float_interval
} from "@/util/control";
import {ref} from "vue";
import {
    chuidiao_time,
    float_flag,
    float_height, float_left, float_top, float_top_max, float_top_min, line_rotate, tmp,
    touch_abler,
    touch_begin,
    touch_begin_flag, touch_end,
    touch_end_flag, touch_end_time, touch_set
} from "@/util/rod";
import {sleep} from "@/util/util";
let range_top = ref(100000)
const range_top_max = 245/375*window.document.body.clientWidth
const range_top_min = 10/375*window.document.body.clientWidth
let rushuiStatus = ref(1)
let shuaiganStatus = ref(1)
const touchstart_event = () => {
    if(touch_begin_flag.value && touch_end_flag.value){ //当蓄力与抛竿动画同时结束
        clearInterval(djs.value)//关闭目标框的移动
        flag.value=false //移动框未开始移动标志
        clearInterval(gamma_set.value)//关闭安卓的定时增长
        rushuiStatus.value = 0//关闭入水的音乐
        window.removeEventListener("deviceorientation", orientationHandler, false) //卸载陀螺仪监听
        /*将力度条归零*/
        target_left.value = null
        document.querySelector('.mubiao').setAttribute('style','transition: all 1s;left:'+target_left_center+'%')
        fill_left.value = null
        document.querySelector('.tianchong').setAttribute('style','transition: all 1s;left:'+fill_left_center+'%')
        time.value = null
        jianchi.value = 0
        document.querySelector('.smalltiao .content').removeAttribute('style')
        line_rotate.value = 20
        touch_abler.value = true
        range_top.value = range_top_max
        isFishing.value = false
        isFlagBig.value = false
        clearInterval(chuidiao_time.value)
        chuidiao_time.value = null
        float_flag.value = false
        float_height.value = 51
        touch_begin_flag.value = false //蓄力动画开始
        touch_begin.value = true //打开蓄力动画

        //打开蓄力框定时器
        clearInterval(touch_set.value)
        touch_set.value = null
        touch_set.value = setInterval(()=>{
            if(range_top.value>=range_top_max){
                tmp.value = -4
            }else if(range_top.value<=range_top_min){
                tmp.value = 4
            }
            range_top.value+=tmp.value
        },10)

        //等待蓄力动画结束
        sleep(1000).then(()=>{
            target_left.value = target_left_center
            fill_left.value = fill_left_center
            touch_begin_flag.value = true
            document.querySelector('.mubiao').removeAttribute('style')
            document.querySelector('.tianchong').removeAttribute('style')
        })
    }
}
const touchend_event = () => {
    //按下一次只能触发一次放开
    if(!touch_abler.value) return
    //必须时蓄力结束 抛竿动画结束
    if(touch_begin_flag.value && touch_end_flag.value){
        touch_abler.value = false
        //清除蓄力框定时器
        clearInterval(touch_set.value)
        touch_set.value = null
        //抛竿
        toss()
    }else if(!touch_begin_flag.value && touch_end_flag.value){
        touch_abler.value = false
        //清除蓄力框定时器
        clearInterval(touch_set.value)
        touch_set.value = null
        //等待蓄力结束
        if(touch_end_time.value==null){
            touch_end_time.value = setInterval(()=>{
                if(touch_begin_flag.value && touch_end_flag.value){
                    clearInterval(touch_end_time.value)
                    touch_end_time.value = null
                    //抛竿
                    toss()
                }
            },10)
        }
    }
}

//抛竿事件
const toss = () => {
    //播放抛竿音效
    shuaiganStatus.value++
    //打开抛竿动画
    touch_end_flag.value = false
    touch_end.value = true
    sleep(500).then(()=>{
        touch_end.value = false
        touch_begin.value = false
        float_top.value=range_top.value/range_top_max*(float_top_max-float_top_min)+float_top_min;
        float_left.value = Math.random()*60+20
        float_flag.value = true //显示浮漂
        return sleep(500)
    }).then(()=>{
        float_height.value = 14
        touch_end_flag.value = true
        chuidiao_time.value = setTimeout(() => {
            rushuiStatus.value++
            isFishing.value = true
            getFishingInfo()
        }, Math.random() * 5000)
    })
}

export {range_top,shuaiganStatus,range_top_max,touchstart_event,touchend_event,rushuiStatus}
