import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import version from '../package.json'
createApp(App).use(router).mount('#app')
//判断版本号
const VUE_APP_VERSION = version.version
const vers = window.localStorage.getItem("Version");
if(VUE_APP_VERSION != vers){
    localStorage.clear()
    window.localStorage.setItem("Version", VUE_APP_VERSION);
    location.reload()
}
/*
* 时间到 不能判断滚动条
* 返回首页 滚动条还能移动*/
